import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  authAdminLoginStart,
  authAdminLoginSuccess,
  authAdminLoginFailure,
  getMyDetailsStart,
  getMyDetailsSuccess,
  getMyDetailsFailure,
  changePasswordStart,
  changePasswordSuccess,
  changePasswordFailure
} from "../Reducer/authAdminReducer";
import axios from "axios";
import Cookies from "js-cookie";
import { GetMyDetailsResponse, LoginResponse, LoginValues } from "../../interfaces";


export const Login = createAsyncThunk<LoginResponse, LoginValues>("authAdmin", async (values, thunkAPI) => {
  try {
    thunkAPI.dispatch(authAdminLoginStart());
    const response = await axios.post<LoginResponse>(
      `${process.env.REACT_APP_API_URl}AdminRoutes/AdminLogin`,
      values
    );

    thunkAPI.dispatch(authAdminLoginSuccess(response));
    Cookies.set("token", response?.data.data);

    return response.data;
  }
  catch (error: any) {
    const errorMessage = error?.response?.data?.message || "Login failed";
    thunkAPI.dispatch(authAdminLoginFailure(errorMessage));
    throw error;
  }
});

export const getMyDetails = createAsyncThunk<GetMyDetailsResponse>(
  "authAdmin",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(getMyDetailsStart());

      const token = Cookies.get("token");
      const config = {
        headers: {
          Authorization: token,
        },
      };

      const response = await axios.get<GetMyDetailsResponse>(
        `${process.env.REACT_APP_API_URl}AdminRoutes/AdminGetMyDetail`,
        config
      );

      thunkAPI.dispatch(getMyDetailsSuccess(response.data));

      return response.data;
    } catch (error: any) {
      const errorMessage = error?.response?.data?.message || "Failed to fetch admin details";

      thunkAPI.dispatch(getMyDetailsFailure(errorMessage));
      throw error;
    }
  }
);


export const changePassword = createAsyncThunk(
  "authAdmin/changePassword",
  async (values: { previousPassword: string; newPassword: string }, thunkAPI) => {
    try {
      thunkAPI.dispatch(changePasswordStart());
      const token = Cookies.get("token");

      const config = {
        headers: {
          Authorization: token,
        },
      };

      const response = await axios.put(
        `${process.env.REACT_APP_API_URl}admin/updatePassword`,
        values,
        config
      );

      const responseData = await response;

      thunkAPI.dispatch(changePasswordSuccess(responseData));
      thunkAPI.dispatch(getMyDetails());
      return responseData;
    } catch (error: any) {
      const errorMessage = error.response?.data?.message || "Failed to update Password!";
      thunkAPI.dispatch(changePasswordFailure(errorMessage));
      return thunkAPI.rejectWithValue(errorMessage);
    }

  }
);
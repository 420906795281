import React, { useState, useEffect } from 'react';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, Input, Button, Upload, message, Select, InputNumber } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../Store';
import { editCourse, getSingleCourse } from '../../Redux/Action/course';
import { clearState } from '../../Redux/Reducer/courseReducer';
import Loading from '../../Lottie/Loading';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { getInstructorListing } from '../../Redux/Action/Instructor';
import { Modal } from 'react-bootstrap';
import EditCourseSectionModal from './EditCourseSectionModal';
import DeleteModal from '../DeleteModal/DeleteModal';
import { deleteCourseSectionPart } from '../../Redux/Action/courseSectionPart';
import AddNewCourseSectionPart from './AddNewCourseSectionPart';

const CourseDetailPage = () => {
    const { id } = useParams<{ id: string }>();
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const { editSuccess, data, editError, loading, editLoading } = useSelector((state: RootState) => state.course);
    const { deleteLoading } = useSelector((state: RootState) => state.courseSectionPart);
    const { data: instructorData } = useSelector((state: RootState) => state.instructor);
    const [courseSectionModal, setCourseSectionModal] = useState<boolean>(false);
    const [idEditSectionParts, setIdEditSectionParts] = useState<any>();
    const [ShowSectionPart, setShowSectionPart] = useState<boolean>(false);
    const [idDelSectionPart, setidDelSectionPart] = useState<string>();
    const [addNewPartId, setAddNewPartId] = useState<string>();
    const [ShowaddNewPart, setShowaddNewPart] = useState<boolean>(false);
    const [RemoveSectionId, setRemoveSectionId] = useState<string>();
    const [ShowRemoveSection, setShowRemoveSection] = useState<boolean>(false);    
    const [pageSize, setPageSize] = useState<number>(100);
    const [page, setPage] = useState<number>(1);
    
    const handleCourseSectionModalClose = () => {
        setCourseSectionModal(false);
    }

    const handleDeleteSectionPart = async () => {
        if (idDelSectionPart) {
            console.log(idDelSectionPart)
            await dispatch(deleteCourseSectionPart(idDelSectionPart));
            setShowSectionPart(false);
            getDataAgain();
        }
        else {
            message.error("Section Part does not exist!")
        }
    };

    const handleDeleteSection = async () => {
        if (RemoveSectionId) {
            console.log(RemoveSectionId)
            await dispatch(deleteCourseSectionPart(RemoveSectionId));
            setShowSectionPart(false);
            getDataAgain();
        }
        else {
            message.error("Section does not exist!")
        }
    };

    const getDataAgain = () => {
        if (id) {
            dispatch(getSingleCourse({ id }));
        }
    }

    const handleCloseDeleteSectionPart = () => {
        setShowSectionPart(false);
    }

    const handleOpenAddNewSectionPart = (addNewPart: string) => {
        setAddNewPartId(addNewPart);
        setShowaddNewPart(true);
    }

    const handleCloseAddNewSectionPart = () => {
        setShowaddNewPart(false);
    }

    const handleShowSectionPart = (id: string) => {
        setShowSectionPart(true);
        setidDelSectionPart(id);
    }

    const handleShowRemoveSection = (id: string) => {
        setRemoveSectionId(id);
        setShowRemoveSection(true);
    }

    const handleRemoveSectionClose = () => {
        setShowRemoveSection(false);
        
    }

    const handleCourseSectionModalOpen = (obj: any) => {
        setIdEditSectionParts(obj);
        setCourseSectionModal(true);
    }

    useEffect(() => {
        if (id) {
            dispatch(getSingleCourse({ id }));
        }
    }, [dispatch, id]);



    useEffect(() => {
        dispatch(getInstructorListing({ page, pageSize }));
    }, [dispatch])


    useEffect(() => {
        if (editError) {
            message.error(editError);
            dispatch(clearState());
        }
        if (editSuccess) {
            message.success(editSuccess);
            dispatch(clearState());
            navigate('/courses');
        }
    }, [editSuccess, editError]);

    const onFinish = (values: any) => {
        const formData = new FormData();
        formData.append('Name', values.Name);
        formData.append('Description', values.Description);
        formData.append('Price', values.Price);
        formData.append('Instructor', values.Instructor);
        formData.append('File', '');

        if (id) {
            dispatch(editCourse({ id, formData }));
        }
    };

    useEffect(() => {
        if (data) {
            form.setFieldsValue({
                Name: data?.Name,
                Price: data?.Price,
                Description: data?.Description,
                Instructor: data?.InstructorId,
                File: data?.File
            });
        }
    }, [data]);

    return (
        <div className='container-fluid servicess'>
            <div className="row" style={{margin: 0}}>
                <div className="col-sm-12 addnew">
                    <h2>Course Details</h2>
                    <Button>Add New Course Section</Button>
                    {/* <Button onClick={() => navigate(`/courses/CourseSections/${id}`)}>View Course Sections</Button> */}
                </div>

                <div className="col-sm-12" style={{ paddingTop: '30px' }}>
                    {
                        loading ?
                            <div>
                                <Loading />
                            </div> :
                            <Form
                                layout='vertical'
                                onFinish={onFinish}
                                initialValues={data}
                                form={form}
                            >
                                <div className="row">
                                    <div className="col-sm-6">
                                        <Form.Item
                                            label="Name"
                                            name="Name"
                                            rules={[{ required: true, message: 'Please input the Name!' }]}
                                        >
                                            <Input placeholder='Name' />
                                        </Form.Item>
                                    </div>

                                    <div className="col-sm-6">
                                        <Form.Item
                                            label="Price"
                                            name="Price"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input the Price!'
                                                }
                                            ]}
                                        >
                                            <InputNumber min={1} placeholder='Price' />
                                        </Form.Item>
                                    </div>

                                    <div className="col-sm-6">
                                        <Form.Item
                                            label="Instructor"
                                            name="Instructor"
                                            rules={[{ required: true, message: 'Please select the Instructor!' }]}
                                        >
                                            <Select placeholder='Instructor'>
                                                {instructorData?.items?.map((instructor: any, index: number) => (
                                                    <Select.Option value={instructor?._id} key={index}>{instructor?.Name}</Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </div>

                                    <div className="col-sm-6">
                                        <Form.Item
                                            label="File"
                                            name="File"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input the File!'
                                                },
                                                {
                                                    type: 'url',
                                                    message: 'Please enter a valid URL!'
                                                }
                                            ]}
                                        >
                                            <Input placeholder='File' />
                                        </Form.Item>
                                    </div>

                                    <div className="col-sm-12">
                                        <Form.Item
                                            label="Description"
                                            name="Description"
                                            rules={[{ required: true, message: 'Please input the description!' }]}
                                        >
                                            <Input.TextArea placeholder='Description' rows={5} />
                                        </Form.Item>
                                    </div>

                                    {data?.CourseSection?.length > 0 ? (
                                        data.CourseSection.map((section: any, sectionIndex: number) => (
                                            <div className="col-sm-12" key={sectionIndex}>
                                                <div className="row" style={{ margin: 0 }}>
                                                    <div className="col-sm-6">
                                                        <h4 style={{ textTransform: 'capitalize' }}>{section?.Name}</h4>
                                                    </div>
                                                    <div className="col-sm-6" style={{paddingRight: 0, display: 'flex', gap: '20px', justifyContent: 'flex-end'}}>
                                                        <Button onClick={() => handleOpenAddNewSectionPart(section?._id)}>Add {section?.Name} Section Part</Button>
                                                        <Button onClick={() => handleShowRemoveSection(section?._id)}>Remove {section?.Name} Section</Button>
                                                    </div>
                                                </div>

                                                {section?.CourseSectionParts?.map((part: any, partIndex: number) => (
                                                    <div className="row" style={{ margin: 0, alignItems: 'center' }} key={partIndex}>
                                                        <div className="col-sm-3">
                                                            <Form.Item label="Title">
                                                                <Input readOnly value={part?.Title} />
                                                            </Form.Item>
                                                        </div>

                                                        <div className="col-sm-4">
                                                            <Form.Item label="File">
                                                                <Input readOnly value={part?.File} />
                                                            </Form.Item>
                                                        </div>

                                                        <div className="col-sm-3">
                                                            <Form.Item label="Duration">
                                                                <Input readOnly value={part?.Duration} />
                                                            </Form.Item>
                                                        </div>

                                                        <div className="col-sm-2" style={{paddingRight: 0, display: 'flex', gap: '20px', justifyContent: 'flex-end'}}>
                                                            <Button onClick={() => handleCourseSectionModalOpen(part)}><EditOutlined /></Button>
                                                            <Button onClick={() => handleShowSectionPart(part?._id)}><DeleteOutlined /></Button>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        ))
                                    ) : (
                                        <></>
                                    )}


                                    <div className="col-sm-12" style={{ textAlign: 'right', marginTop: '20px' }} >
                                        <Button type="primary" htmlType="submit" disabled={editLoading} className='spinnnnerr'>
                                            {editLoading ?
                                                <>
                                                    <Spin indicator={<LoadingOutlined spin />} />
                                                </>
                                                :
                                                <></>
                                            }

                                            Submit</Button>
                                    </div>
                                </div>
                            </Form>
                    }
                </div>
            </div>

            <Modal show={courseSectionModal} className='courseSectionCHanges' onHide={handleCourseSectionModalClose}>
                <Modal.Header closeButton><b>Edit Course Section Part</b></Modal.Header>
                <Modal.Body>
                    <EditCourseSectionModal idEditSectionParts={idEditSectionParts} handleCourseSectionModalClose={handleCourseSectionModalClose} getDataAgain={getDataAgain} />
                </Modal.Body>
            </Modal>

            <Modal show={ShowSectionPart} onHide={handleCloseDeleteSectionPart}>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <DeleteModal handleClose={handleCloseDeleteSectionPart} handleDelete={handleDeleteSectionPart} loading={deleteLoading} />
                </Modal.Body>
            </Modal>

            <Modal show={ShowRemoveSection} onHide={handleRemoveSectionClose}>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <DeleteModal handleClose={handleRemoveSectionClose} handleDelete={handleDeleteSection} loading={deleteLoading} />
                </Modal.Body>
            </Modal>

            <Modal show={ShowaddNewPart} onHide={handleCloseAddNewSectionPart}>
                <Modal.Header closeButton><b>Add Course Section Part</b></Modal.Header>
                <Modal.Body>
                    <AddNewCourseSectionPart handleClose={handleCloseAddNewSectionPart} idEditSectionParts={addNewPartId} getDataAgain={getDataAgain} />
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default CourseDetailPage;

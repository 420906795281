import { createSlice } from "@reduxjs/toolkit";

interface workshop {
    loading: boolean;
    editLoading: boolean;
    deleteLoading: boolean;
    error: string | null;
    success: string | null;
    deleteError: string | null;
    deleteSuccess: string | null;
    workshopData: any;
    postError: string | null;
    postSuccess: string | null;
    editError: string | null,
    editSuccess: string | null,
}

const initialState: workshop = {
    loading: false,
    deleteLoading: false,
    editLoading: false,
    error: null,
    success: null,
    deleteError: null,
    deleteSuccess: null,
    postError: null,
    postSuccess: null,
    editError: null,
    editSuccess: null,
    workshopData: {},
};

const workshopSlice = createSlice({
    name: "workshop",
    initialState,
    reducers: {
        workshopListingStart(state) {
            state.loading = true;
            state.error = null;
        },

        workshopListingSuccess(state, action) {
            state.loading = false;
            state.workshopData = action.payload.data.result;
            state.success = action.payload.data.message;
        },
        workshopListingFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        singleWorkshopStart(state) {
            state.loading = true;
            state.error = null;
        },
        singleWorkshopSuccess(state, action) {
            state.loading = false;
            state.workshopData = action.payload.data;
            state.success = action.payload.data.message;
        },
        singleWorkshopFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        deleteWorkshopStart(state) {
            state.deleteLoading = true;
            state.deleteError = null;
        },
        deleteWorkshopSuccess(state, action) {
            state.deleteLoading = false;
            state.workshopData = action.payload;
            state.deleteSuccess = action.payload.message;
        },
        deleteWorkshopFailure(state, action) {
            state.deleteLoading = false;
            state.deleteError = action.payload;
        },
        addWorkshopStart(state) {
            state.loading = true;
            state.postError = null;
        },
        addWorkshopSuccess(state, action) {
            state.loading = false;
            state.workshopData = action.payload.data;
            state.postSuccess = action.payload.data.message;
        },
        addWorkshopFailure(state, action) {
            state.loading = false;
            state.postError = action.payload;
        },
        editWorkshopStart(state) {
            state.editLoading = true;
            state.editError = null;
        },
        editWorkshopSuccess(state, action) {
            state.editLoading = false;
            state.workshopData = action.payload.data;
            state.editSuccess = action.payload.data.message;
        },
        editWorkshopFailure(state, action) {
            state.editLoading = false;
            state.editError = action.payload;
        },
        clearState: (state) => {
            state.error = null;
            state.success = null;
            state.deleteError = null;
            state.deleteSuccess = null;
            state.postError = null;
            state.postSuccess = null;
            state.editSuccess = null;
            state.editError = null;
            state.loading = false;
            state.deleteLoading = false;
            state.editLoading = false;
        },
    },
});

export const {
    workshopListingStart,
    workshopListingSuccess,
    workshopListingFailure,
    singleWorkshopStart,
    singleWorkshopFailure,
    singleWorkshopSuccess,
    deleteWorkshopStart,
    deleteWorkshopFailure,
    deleteWorkshopSuccess,
    addWorkshopStart,
    addWorkshopSuccess,
    addWorkshopFailure,
    editWorkshopStart,
    editWorkshopFailure,
    editWorkshopSuccess,
    clearState,
} = workshopSlice.actions;

export default workshopSlice.reducer;
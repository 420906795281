import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, Input, Button, Upload, message, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../Store';
import { editCourse, getSingleCourse } from '../../Redux/Action/course';
import { clearState } from '../../Redux/Reducer/courseReducer';
import Loading from '../../Lottie/Loading';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { getInstructorListing } from '../../Redux/Action/Instructor';

const CourseSectiomDetailPage = () => {
    const dispatch = useDispatch<AppDispatch>(); 
    const { editSuccess, data, editError, loading, editLoading } = useSelector((state: RootState) => state.course);
    const { data: instructorData } = useSelector((state: RootState) => state.instructor);

    const { id } = useParams<{ id: string }>();
    const [form] = Form.useForm();
    const navigate = useNavigate();

    useEffect(() => {
        if (id) {
            dispatch(getSingleCourse({ id }));
        }
    }, [dispatch, id]);

    const [pageSize, setPageSize] = useState<number>(100);
    const [page, setPage] = useState<number>(1);

    useEffect(() => {
        dispatch(getInstructorListing({ page, pageSize }));
    }, [dispatch])


    useEffect(() => {
        if(editError) {
            message.error(editError);
            dispatch(clearState());
        }
        if(editSuccess) {
            message.success(editSuccess);
            dispatch(clearState());
            navigate('/courses');
        }
    }, [editSuccess, editError]);
    
    const onFinish = (values: any) => {
        const formData = new FormData();
        formData.append('Name', values.Name);
        formData.append('Description', values.Description);
        formData.append('Price', values.Price);
        formData.append('Instructor', values.Instructor);
        formData.append('File', '');

        if(id) {
            dispatch(editCourse({id, formData}));
        }
    };    
    
    useEffect(() => {
        if(data) {
            form.setFieldsValue({
                Name: data?.Name,
                Price: data?.Price,
                Description: data?.Description,
                Instructor: data?.InstructorId,
                File: data?.File
            });
        }
    }, [data]);
    
    return (
        <div className='container-fluid servicess'>
            <div className="row">
                <div className="col-sm-12 addnew">
                    <h2>Course Section Details</h2>
                    {/* <Button onClick={() => navigate(`/courses/CourseSections/${id}`)}>View Course Sections</Button> */}
                </div>

                <div className="col-sm-12" style={{paddingTop: '30px'}}>
                    {
                        loading ? 
                        <div>
                            <Loading />
                        </div> :
                        <Form 
                            layout='vertical' 
                            onFinish={onFinish} 
                            initialValues={data} 
                            form={form}
                        >
                            <div className="row">
                                <div className="col-sm-6">
                                    <Form.Item
                                        label="Name"
                                        name="Name"
                                        rules={[{ required: true, message: 'Please input the Name!' }]} 
                                    >
                                        <Input placeholder='Name' />
                                    </Form.Item>
                                </div>

                                <div className="col-sm-6">
                                    <Form.Item
                                        label="Price"
                                        name="Price"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input the Price!'
                                            },
                                            {
                                                validator: (_, value) => {
                                                    if (!value || (Number(value) > 0)) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(new Error('Price must be greater than 0!'));
                                                }
                                            }
                                        ]}
                                    >
                                        <Input placeholder='Price' />
                                    </Form.Item>
                                </div>

                                <div className="col-sm-6">
                                    <Form.Item
                                        label="Instructor"
                                        name="Instructor"
                                        rules={[{ required: true, message: 'Please select the Instructor!' }]}
                                    >
                                        <Select placeholder='Instructor'>
                                            {instructorData?.items?.map((instructor: any, index: number) => (
                                                <Select.Option value={instructor?._id} key={index}>{instructor?.Name}</Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </div>

                                <div className="col-sm-6">
                                    <Form.Item
                                        label="File"
                                        name="File"
                                        rules={[
                                            { 
                                                required: true, 
                                                message: 'Please input the File!' 
                                            },
                                            {
                                                type: 'url',
                                                message: 'Please enter a valid URL!'
                                            }
                                        ]} 
                                    >
                                        <Input placeholder='File' />
                                    </Form.Item>
                                </div>

                                <div className="col-sm-12">
                                    <Form.Item
                                        label="Description"
                                        name="Description"
                                        rules={[{ required: true, message: 'Please input the description!' }]} 
                                    >
                                        <Input.TextArea placeholder='Description' rows={5} />
                                    </Form.Item>
                                </div>

                                <div className="col-sm-12" style={{textAlign: 'right', marginTop: '20px'}} >
                                    <Button type="primary" htmlType="submit" disabled={editLoading} className='spinnnnerr'>
                                    {editLoading ? 
                                        <>
                                            <Spin indicator={<LoadingOutlined spin />}  /> 
                                        </>
                                        : 
                                        <></> 
                                        }
                                        
                                        Submit</Button>
                                </div>
                            </div>
                        </Form>
                    }
                </div>
            </div>
        </div>
    );
};

export default CourseSectiomDetailPage;

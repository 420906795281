import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Input, Button, Select, message, Space } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../Store';
import { addCourse } from '../../Redux/Action/course';
import { clearState } from '../../Redux/Reducer/courseReducer';
import { LoadingOutlined } from '@ant-design/icons';
import { getInstructorListing } from '../../Redux/Action/Instructor';
import { Spin } from 'antd';

const AddCoursePage = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { data } = useSelector((state: RootState) => state.instructor);
    const { postError, postSuccess, loading } = useSelector((state: RootState) => state.course);
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [pageSize] = useState<number>(100);
    const [page] = useState<number>(1);
    const [showAddSectionButton, setShowAddSectionButton] = useState<boolean>(true);

    useEffect(() => {
        dispatch(getInstructorListing({ page, pageSize }));
    }, [dispatch]);

    useEffect(() => {
        if (postError) {
            message.error(postError);
            dispatch(clearState());
        }
        if (postSuccess) {
            message.success(postSuccess);
            dispatch(clearState());
            navigate('/courses');
        }
    }, [postSuccess, postError, dispatch, navigate]);

    const onFinish = (values: any) => {
        const formattedSections = values.sections.map((section: any) => ({
          Name: section.Name,
          SectionParts: section?.SectionParts?.map((part: any) => ({
            Title: part.Title,
            File: part.File,
            Duration: part.Duration,
          })),
        }));
      
        const formData = {
          ...values,
          CourseSections: formattedSections,
        };
      
        dispatch(addCourse(formData));
      };
      



    return (
        <div className='container-fluid servicess'>
            <div className="row" style={{ margin: 0 }}>
                <div className="col-sm-12 addnew">
                    <h2>Add New Course</h2>
                </div>

                <div className="col-sm-12" style={{ paddingTop: '30px' }}>
                    <Form form={form} layout='vertical' onFinish={onFinish}>
                        <div className="row">
                            <div className="col-sm-6">
                                <Form.Item label="Name" name="Name" rules={[{ required: true, message: 'Please input the Name!' }]}>
                                    <Input placeholder='Name' />
                                </Form.Item>
                            </div>

                            <div className="col-sm-6">
                                <Form.Item label="Price" name="Price" rules={[
                                    { required: true, message: 'Please input the Price!' },
                                    { validator: (_, value) => !value || Number(value) > 0 ? Promise.resolve() : Promise.reject('Price must be greater than 0!') }
                                ]}>
                                    <Input placeholder='Price' />
                                </Form.Item>
                            </div>

                            <div className="col-sm-6">
                                <Form.Item label="Instructor" name="Instructor" rules={[{ required: true, message: 'Please input the Instructor!' }]}>
                                    <Select placeholder='Instructor'>
                                        {data?.items?.map((instructor: any) => (
                                            <Select.Option key={instructor?._id} value={instructor?._id}>
                                                {instructor?.Name}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </div>

                            <div className="col-sm-6">
                                <Form.Item label="File" name="File" rules={[
                                    { required: true, message: 'Please input the File!' },
                                    { type: 'url', message: 'Please enter a valid URL!' }
                                ]}>
                                    <Input placeholder='File' />
                                </Form.Item>
                            </div>

                            <div className="col-sm-12">
                                <Form.Item label="Description" name="Description" rules={[{ required: true, message: 'Please input the description!' }]}>
                                    <Input.TextArea placeholder='Course Description' rows={5} />
                                </Form.Item>
                            </div>

                            <Form.List name="sections">
  {(fields, { add, remove }) => (
    <>
      {fields.map(({ key, name }) => (
        <div key={key} className="col-sm-12" style={{ padding: 0 }}>
          <div className="row" style={{ margin: 0 }}>
            <div className="col-sm-12" style={{ display: 'flex', justifyContent: 'space-between' }}>
              <h4>Section {name + 1}</h4>
              <Button danger onClick={() => remove(name)} style={{ marginBottom: '10px' }}>
                Remove Section {name + 1}
              </Button>
            </div>

            <div className="col-sm-12">
              <Form.Item
                label="Section Name"
                name={[name, 'Name']}
                // fieldKey={[fieldKey, 'Name']}
                rules={[{ required: true, message: 'Name is required!' }]}
              >
                <Input placeholder="Section Name" />
              </Form.Item>
            </div>

            <Form.List name={[name, 'SectionParts']}>
              {(partFields, { add: addPart, remove: removePart }) => (
                <>
                  {partFields.map(({ key: partKey, name: partName }) => (
                    <div key={partKey} className="row" style={{ margin: 0, padding: '0 20px', alignItems: 'center' }}>
                      <h5>Section Part {partName + 1}</h5>

                      <div className="col-sm-3">
                        <Form.Item
                          name={[partName, 'Title']}
                        //   fieldKey={[partFieldKey, 'Title']}
                          label="Title"
                          rules={[{ required: true, message: 'Title is required!' }]}
                        >
                          <Input placeholder="Title" />
                        </Form.Item>
                      </div>

                      <div className="col-sm-4">
                        <Form.Item
                          name={[partName, 'File']}
                        //   fieldKey={[partFieldKey, 'File']}
                          label="File"
                          rules={[
                            { required: true, message: 'File URL is required!' },
                            { type: 'url', message: 'Please enter a valid URL!' },
                          ]}
                        >
                          <Input placeholder="File URL" />
                        </Form.Item>
                      </div>

                      <div className="col-sm-3">
                      <Form.Item
  name={[partName, 'Duration']}
  label="Duration"
  rules={[{ required: true, message: 'Duration is required!' }]}
>
  <Input placeholder="Duration (e.g. 210 min)" />
</Form.Item>
                      </div>

                      <div className="col-sm-2">
                        <Button danger onClick={() => removePart(partName)} style={{ marginBottom: '10px' }}>
                          Remove Part
                        </Button>
                      </div>
                    </div>
                  ))}

                  <div className="col-sm-12" style={{ textAlign: 'right' }}>
                    <Button onClick={() => addPart()}>Add Section {name + 1} Part</Button>
                  </div>
                </>
              )}
            </Form.List>
          </div>
        </div>
      ))}

      <div className="col-sm-12">
        <Button onClick={() => add()}>Add New Section</Button>
      </div>
    </>
  )}
</Form.List>


                            <div className="col-sm-12" style={{ textAlign: 'right', marginTop: '20px' }}>
                                <Button type="primary" htmlType="submit" disabled={loading} className='spinnnnerr'>
                                    {loading ? <Spin indicator={<LoadingOutlined spin />} /> : 'Submit'}
                                </Button>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    );
};

export default AddCoursePage;

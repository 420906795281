import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  courseSectionPartListingStart,
  courseSectionPartListingSuccess,
  courseSectionPartListingFailure,
  singlecourseSectionPartStart,
  singlecourseSectionPartFailure,
  singlecourseSectionPartSuccess,
  editcourseSectionPartStart,
  editcourseSectionPartFailure,
  editcourseSectionPartSuccess,
  addcourseSectionPartStart,
  addcourseSectionPartFailure,
  addcourseSectionPartSuccess,
  deletecourseSectionPartStart,
  deletecourseSectionPartFailure,
  deletecourseSectionPartSuccess
} from "../Reducer/courseSectionPartsReducer";
import axios from "axios";
import Cookies from "js-cookie";
import { PaginationParams, ServiceListingResponse, SingleServiceResponse } from "../../interfaces";

// export const getCourseListing = createAsyncThunk<ServiceListingResponse, PaginationParams>(
//   "courseSectionPart",
//   async ({ page, pageSize }, thunkAPI) => {
//     try {
//       thunkAPI.dispatch(courseListingStart());

//       const token = Cookies.get("token");
//       const config = {
//         headers: {
//           Authorization: token,
//         },
//         params: {
//           size: pageSize,
//           page,
//         },
//       };

//       const response = await axios.get<ServiceListingResponse>(
//         `${process.env.REACT_APP_API_URl}CourseRoutes/CoursesListing`,
//         config
//       );

//       thunkAPI.dispatch(courseListingSuccess(response));

//       return response.data;
//     } catch (error: any) {
//       const errorMessage = error?.response?.data?.message || "Failed to fetch course details";

//       thunkAPI.dispatch(courseListingFailure(errorMessage));
//       throw error;
//     }
//   }
// );


// export const getSingleCourse = createAsyncThunk<SingleServiceResponse, { id: string }, { rejectValue: string }>(
//   "course/getSingleCourse",
//   async ({ id }, thunkAPI) => {
//     try {
//       thunkAPI.dispatch(singleCourseStart());

//       const token = Cookies.get("token");
//       const config = {
//         headers: {
//           Authorization: token,
//         },
//       };

//       const response = await axios.get<SingleServiceResponse>(
//         `${process.env.REACT_APP_API_URl}CourseRoutes/SingleCourse/${id}`,
//         config
//       );

//       thunkAPI.dispatch(singleCourseSuccess(response.data));
//       return response.data;
//     } catch (error: any) {
//       const errorMessage = error?.response?.data?.message || "Failed to fetch course details";
//       thunkAPI.dispatch(singleCourseFailure(errorMessage));
//       return thunkAPI.rejectWithValue(errorMessage);
//     }
//   }
// );

export const addCourseSectionPart = createAsyncThunk<SingleServiceResponse, { id: string, formData: FormData }, { rejectValue: string }>(
  "courseSectionPart/addCourseSectionPart",
  async ({id, formData}, thunkAPI) => {
    try {
      thunkAPI.dispatch(addcourseSectionPartStart());

      const token = Cookies.get("token");
      const config = {
        headers: {
          Authorization: token,
        },
      };

      const response = await axios.post(
        `${process.env.REACT_APP_API_URl}CourseSectionPartsRoutes/CreateCourseSectionPart/${id}`,
        formData,
        config
      );

      thunkAPI.dispatch(addcourseSectionPartSuccess(response));
      return response.data;
    } catch (error: any) {
      const errorMessage = error.response?.data?.message || "Failed to add course section part!";
      thunkAPI.dispatch(addcourseSectionPartFailure(errorMessage));
      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);

export const editCourseSectionPart = createAsyncThunk<SingleServiceResponse, { id: string, formData: FormData }, { rejectValue: string }>(
  "courseSectionPart/editCourseSectionPart",
  async ({id, formData}, thunkAPI) => {
    try {
      thunkAPI.dispatch(editcourseSectionPartStart());

      const token = Cookies.get("token");
      const config = {
        headers: {
          Authorization: token,
        },
      };

      const response = await axios.put(
        `${process.env.REACT_APP_API_URl}CourseSectionPartsRoutes/EditCourseSectionPart/${id}`,
        formData,
        config
      );

      thunkAPI.dispatch(editcourseSectionPartSuccess(response));
      return response.data;
    } catch (error: any) {
      const errorMessage = error.response?.data?.message || "Failed to edit course section part!";
      thunkAPI.dispatch(editcourseSectionPartFailure(errorMessage));
      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);

export const deleteCourseSectionPart = createAsyncThunk(
  "courseSectionPart/deleteCourseSectionPart",
  async (id: string, thunkAPI) => {
    try {
      thunkAPI.dispatch(deletecourseSectionPartStart());

      const token = Cookies.get("token");
      const config = {
        headers: {
          Authorization: token,
        },
      };

      const response = await axios.delete(
        `${process.env.REACT_APP_API_URl}CourseSectionPartsRoutes/SoftDeleteCourseSectionPart/${id}`, config
      );

      thunkAPI.dispatch(deletecourseSectionPartSuccess(response.data));
      return response.data;
    } catch (error: any) {
      if (error) {
        const errorMessage = error?.response?.data?.message;
        thunkAPI.dispatch(deletecourseSectionPartFailure(errorMessage));
        return thunkAPI.rejectWithValue(errorMessage);
      }
    }
  }
);
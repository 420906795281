import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, Input, Button, message, Select, InputNumber } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../Store';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { getInstructorListing } from '../../Redux/Action/Instructor';
import { addService, editService, getSingleService } from '../../Redux/Action/service';
import { clearState } from '../../Redux/Reducer/serviceReducer';

// const {option} = Select;

const EditServicePage = () => {
    const { id } = useParams<{ id: string }>();

    const dispatch = useDispatch<AppDispatch>();
    const { data: instructorData } = useSelector((state: RootState) => state.instructor);
    const { editError, editSuccess, loading, data } = useSelector((state: RootState) => state.service);
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [pageSize, setPageSize] = useState<number>(100);
    const [page, setPage] = useState<number>(1);

    useEffect(() => {
        dispatch(getInstructorListing({ page, pageSize }));
    }, [dispatch])

    useEffect(() => {
        if (id) {
            dispatch(getSingleService({ id }));
        }
    }, [dispatch, id]);


    useEffect(() => {
        if (editError) {
            message.error(editError);
            dispatch(clearState());
        }
        if (editSuccess) {
            message.success(editSuccess);
            dispatch(clearState());
            navigate('/services')
        }
    }, [editSuccess, editError]);

    const onFinish = (values: any) => {
        const formData = new FormData();
        formData.append('Name', values.Name);
        formData.append('Description', values.Description);
        formData.append('Price', values.Price);
        formData.append('Duration', values.Duration);
        formData.append('Instructor', values.Instructor);
        formData.append('File', values.File);
        if(id)
        {
            dispatch(editService({id, formData}));
        }
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    useEffect(() => {
        if (data) {
            form.setFieldsValue({
                Name: data?.Name,
                Price: data?.Price,
                Description: data?.Description,
                Instructor: data?.InstructorId,
                File: data?.File,
                Duration: data?.Duration
            });
        }
    }, [data]);

    return (
        <div className='container-fluid servicess'>
            <div className="row">
                <div className="col-sm-12 addnew">
                    <h2>Edit Service</h2>
                </div>

                <div className="col-sm-12" style={{ paddingTop: '30px' }}>
                    <Form
                        form={form}
                        layout='vertical'
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        initialValues={data}
                    >
                        <div className="row">
                            <div className="col-sm-6">
                                <Form.Item
                                    label="Name"
                                    name="Name"
                                    rules={[{ required: true, message: 'Please input the Name!' }]}
                                >
                                    <Input placeholder='Name' />
                                </Form.Item>
                            </div>

                            <div className="col-sm-6">
                                <Form.Item
                                    label="Price"
                                    name="Price"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input the Price!'
                                        }
                                    ]}
                                >
                                    <InputNumber min={1} placeholder='Price' />
                                </Form.Item>
                            </div>


                            <div className="col-sm-6">
                                <Form.Item
                                    label="Duration"
                                    name="Duration"
                                    rules={[{ required: true, message: 'Please input the Duration in mins or hours!' }]}
                                >
                                    <Input placeholder='Duration in mins or hours' />
                                </Form.Item>
                            </div>



                            <div className="col-sm-6">
                                <Form.Item
                                    label="File Link"
                                    name="File"
                                    rules={[
                                        { 
                                            required: true, 
                                            message: 'Please input the File Link!' 
                                        },
                                        {
                                            type: 'url',
                                            message: 'Please enter a valid URL!'
                                        }
                                    ]}
                                >
                                    <Input placeholder='File Link' />
                                </Form.Item>
                            </div>

                            <div className="col-sm-6">
                                <Form.Item
                                    label="Instructor"
                                    name="Instructor"
                                    rules={[{ required: true, message: 'Please select the Instructor!' }]}
                                >
                                    <Select placeholder='Instructor'>
                                        {instructorData?.items?.map((instructor: any, index: number) => (
                                            <Select.Option value={instructor?._id} key={index}>{instructor?.Name}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </div>

                            <div className="col-sm-12">
                                <Form.Item
                                    label="Description"
                                    name="Description"
                                    rules={[{ required: true, message: 'Please input the description!' }]}
                                >
                                    <Input.TextArea placeholder='Book Description' rows={5} />
                                </Form.Item>
                            </div>

                            <div className="col-sm-12" style={{ textAlign: 'right', marginTop: '20px' }}>
                                <Button type="primary" htmlType="submit" disabled={loading} className='spinnnnerr'>
                                    {loading ?
                                        <>
                                            <Spin indicator={<LoadingOutlined spin />} />
                                        </>
                                        :
                                        <></>
                                    }
                                    Submit
                                </Button>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    );
};

export default EditServicePage;
import { createSlice } from "@reduxjs/toolkit";

interface courseSectionPartState {
    loading: boolean;
    editLoading: boolean;
    deleteLoading: boolean;
    error: string | null;
    success: string | null;
    editError: string | null;
    editSuccess: string | null;
    postError: string | null;
    postSuccess: string | null;
    deleteError: string | null;
    deleteSuccess: string | null;
    data: any;
}

const initialState: courseSectionPartState = {
    loading: false,
    deleteLoading: false,
    editLoading: false,
    error: null,
    success: null,
    editError: null,
    editSuccess: null,
    postError: null,
    postSuccess: null,
    deleteError: null,
    deleteSuccess: null,
    data: {},
};

const courseSectionPartSlice = createSlice({
    name: "courseSectionPart",
    initialState,
    reducers: {
        courseSectionPartListingStart(state) {
            state.loading = true;
            state.error = null;
        },

        courseSectionPartListingSuccess(state, action) {
            state.loading = false;
            state.data = action.payload.data.result;
            state.success = action.payload.data.message;
        },
        courseSectionPartListingFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        singlecourseSectionPartStart(state) {
            state.loading = true;
            state.error = null;
        },
        singlecourseSectionPartSuccess(state, action) {
            state.loading = false;
            state.data = action.payload.data;
            state.success = action.payload.data.message;
        },
        singlecourseSectionPartFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        editcourseSectionPartStart(state) {
            state.editLoading = true;
            state.editError = null;
        },
        editcourseSectionPartSuccess(state, action) {
            state.editLoading = false;
            state.data = action.payload.data;
            state.editSuccess = action.payload.data.message;
            
        },
        editcourseSectionPartFailure(state, action) {
            state.editLoading = false;
            state.editError = action.payload;
        },
        addcourseSectionPartStart(state) {
            state.loading = true;
            state.postError = null;
        },
        addcourseSectionPartSuccess(state, action) {
            state.loading = false;
            state.data = action.payload.data;
            state.postSuccess = action.payload.data.message;
        },
        addcourseSectionPartFailure(state, action) {
            state.loading = false;
            state.postError = action.payload;
        },
        deletecourseSectionPartStart(state) {
            state.deleteLoading = true;
            state.deleteError = null;
        },
        deletecourseSectionPartSuccess(state, action) {
            state.deleteLoading = false;
            state.data = action.payload;
            state.deleteSuccess = action.payload.message;
        },
        deletecourseSectionPartFailure(state, action) {
            state.deleteLoading = false;
            state.deleteError = action.payload;
        },
        clearState: (state) => {
            state.error = null;
            state.success = null;
            state.editSuccess = null;
            state.editError = null;
            state.postError = null;
            state.postSuccess = null;
            state.deleteError = null;
            state.deleteSuccess = null;
        },
    },
});

export const {
    courseSectionPartListingStart,
    courseSectionPartListingSuccess,
    courseSectionPartListingFailure,
    singlecourseSectionPartStart,
    singlecourseSectionPartFailure,
    singlecourseSectionPartSuccess,
    editcourseSectionPartStart,
    editcourseSectionPartFailure,
    editcourseSectionPartSuccess,
    addcourseSectionPartStart,
    addcourseSectionPartFailure,
    addcourseSectionPartSuccess,
    deletecourseSectionPartStart,
    deletecourseSectionPartFailure,
    deletecourseSectionPartSuccess,
    clearState,
} = courseSectionPartSlice.actions;

export default courseSectionPartSlice.reducer;
import { Form, Input, Button, message } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../Store';
import { editCourseSectionPart } from '../../Redux/Action/courseSectionPart';
import { clearState } from '../../Redux/Reducer/courseSectionPartsReducer';

interface EditCourseSectionModalProps {
    idEditSectionParts?: any;
    handleCourseSectionModalClose: () => void;
    getDataAgain: () => void;
}

const EditCourseSectionModal: React.FC<EditCourseSectionModalProps> = ({ idEditSectionParts, handleCourseSectionModalClose, getDataAgain }) => {
    const id = idEditSectionParts?._id;
    const dispatch = useDispatch<AppDispatch>();
    const { editSuccess, editError } = useSelector((state: RootState) => state.courseSectionPart);
    
    useEffect(() => {
        if (editError) {
            message.error(editError);
            dispatch(clearState());
        }
        if (editSuccess) {
            message.success(editSuccess);
            dispatch(clearState());
            handleCourseSectionModalClose();
            getDataAgain();
        }
    }, [editSuccess, editError]);


    const onFinish = (values: any) => {
        const formData = new FormData();
        formData.append('Title', values.Title);
        formData.append('File', values.File);
        formData.append('Duration', values.Duration);
        dispatch(editCourseSectionPart({id, formData}))
    };
    const [form] = Form.useForm();


    useEffect(() => {
        if (idEditSectionParts) {
            form.setFieldsValue({
                Title: idEditSectionParts?.Title,
                File: idEditSectionParts?.File,
                Duration: idEditSectionParts?.Duration
            });
        }
    }, [idEditSectionParts]);

    return (
        <div className="container-fluid servicess">
            <Form onFinish={onFinish} layout="vertical" initialValues={idEditSectionParts}>
                <div className="row">
                    <div className="col-sm-12">
                        <Form.Item
                            label="Title"
                            name="Title"
                            rules={[{ required: true, message: 'Please input the Title!' }]}
                        >
                            <Input placeholder="Title" />
                        </Form.Item>
                    </div>

                    <div className="col-sm-12">
                        <Form.Item
                            label="File"
                            name="File"
                            rules={[{ required: true, message: 'Please input the File URL!' },
                                { type: 'url', message: 'Please enter a valid URL!' }]}
                        >
                            <Input placeholder="File URL" />
                        </Form.Item>
                    </div>

                    <div className="col-sm-12">
                        <Form.Item
                            label="Duration"
                            name="Duration"
                            rules={[{ required: true, message: 'Please input the Duration!' }]}
                        >
                            <Input placeholder="Duration" />
                        </Form.Item>
                    </div>

                    <div className="col-sm-12" style={{ textAlign: 'right' }}>
                        <Button type="primary" htmlType="submit">
                            Save Changes
                        </Button>
                    </div>
                </div>
            </Form>
        </div>
    );
};

export default EditCourseSectionModal;

import { Button, Form, Input, message } from 'antd';
// import { EditOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../Store';
import Loading from '../../Lottie/Loading';
import { getMyDetails } from '../../Redux/Action/authAdmin';
import NewPasswordForm from './NewPasswordForm';

const ProfilePage = () => {

    const dispatch = useDispatch<AppDispatch>();
    const { error, data, loginStatus } = useSelector((state: RootState) => state.authAdmin);
    const [form] = Form.useForm();
    const [show, setShow] = useState<boolean>(false);

    useEffect(() => {
        dispatch(getMyDetails());
    }, [dispatch])

    useEffect(() => {
        if (data) {
            form.setFieldsValue({
                Email: data?.Email,
                Name: data?.Name,
            })
        }
    }, [data])

    const handleClose = () => {
        setShow(false);
    }

    const handleShow = () => {
        setShow(true);
    }

    return (
        <div className='container-fluid servicess'>
            <div className="row">
                <div className="col-sm-12 addnew">
                    <h2>Profile</h2>
                </div>

                <div className="col-sm-12" style={{ paddingTop: '30px' }}>
                    <Form layout='vertical' form={form} initialValues={data}>
                        <div className="row">
                            <div className="col-sm-6">
                                <Form.Item
                                    label="Name"
                                    name="Name"
                                >
                                    <Input readOnly placeholder='Name' />
                                </Form.Item>
                            </div>
                            <div className="col-sm-6">
                                <Form.Item
                                    label="Email"
                                    name="Email"
                                >
                                    <Input readOnly placeholder='Email' />
                                </Form.Item>
                            </div>

                            {/* <div className="col-sm-12" style={{
                                textAlign: 'right', paddingTop: '20px'
                            }}>
                                <Button type='primary' onClick={handleShow}>
                                    Change Password
                                </Button>
                            </div> */}
                        </div>
                    </Form>
                </div>
            </div>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <h5>Change Password</h5>
                </Modal.Header>
                <Modal.Body>
                    <NewPasswordForm handleClose={handleClose}/>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default ProfilePage;
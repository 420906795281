import { Button, message } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
// import { EditOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../Store';
import { deleteBook, getBookListing } from '../../Redux/Action/books';
import { clearState } from '../../Redux/Reducer/booksReducer';
import moment from 'moment';
import Pagination from '../Pagination/Pagination';
import Loading from '../../Lottie/Loading';
import DeleteModal from '../DeleteModal/DeleteModal';

const Page = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { data, deleteLoading, loading, deleteSuccess, deleteError } = useSelector((state: RootState) => state.book);
    const navigate = useNavigate();
    const [show, setShow] = useState<boolean>(false);
    const [id, setId] = useState<string>();
    const [pageSize, setPageSize] = useState<number>(10);
    const [page, setPage] = useState<number>(1);

    const handleDelete = async () => {
        if (id) {
            console.log(id)
            await dispatch(deleteBook(id));
            setShow(false);
        }
        else {
            message.error("Book does not exist!")
        }
    };

    const handlePageChange = (page: number, pageSize?: number) => {
        setPage(page);
        setPageSize(pageSize || 10);
    };

    const handleShow = (id: string) => {
        setShow(true);
        setId(id);
    }
    const handleClose = () => {
        setShow(false);
    }
const handleNavigate = (id:string)=>{

    navigate(`/books/${id}`)
}
    useEffect(() => {
        dispatch(getBookListing({page, pageSize}));
    },[page, pageSize])

    useEffect(() => {
        if (deleteError) {
            message.error(deleteError);
            dispatch(clearState());
        }
        if (deleteSuccess) {
            message.success(deleteSuccess);
            dispatch(clearState());
            dispatch(getBookListing({page, pageSize}));
        }
    }, [dispatch, deleteError, deleteSuccess])

    return (
        <div className='container-fluid servicess'>
            <div className="row">
                <div className="col-sm-12 addnew">
                    <h2>Books</h2>
                    <Button onClick={() => navigate('/books/add')}>Add New</Button>
                </div>

            </div>
                    {
                        loading ?
                        <div>
                            <Loading />
                        </div>
                        :
            <div className="row">

                <div className="col-sm-12 ttable">
                    <table>
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>Book Name</th>
                                <th>Author Name</th>
                                <th>Price</th>
                                <th>Description</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                data?.items?.length > 0 ?
                                
                                data?.items?.map((book:any, index:number) => (
                                    <tr key={book._id}>
                                        <td onClick={() => handleNavigate(book._id)} >{index + 1}</td>
                                        <td onClick={() => handleNavigate(book._id)}><b>{book?.Name}</b></td>
                                        <td onClick={() => handleNavigate(book._id)}><b>{book?.AuthorName}</b></td>
                                        <td onClick={() => handleNavigate(book._id)}>{book?.Price}</td>
                                        <td onClick={() => handleNavigate(book._id)}>{book.Description.split(' ').slice(0, 10).join(' ') + (book.Description.split(' ').length > 10 ? ' ...' : '')}</td>
                                        <td className='deleete'>
                                            <div className='actionsss'>
                                                <DeleteOutlined className='del' onClick={() => handleShow(book._id)} />
                                            </div>
                                        </td>
                                    </tr>
                                ))

                                : 
                                <tr className='noDataa'>
                                    <td colSpan={6}>No Data</td>
                                </tr>
                            }
                        </tbody>
                    </table>
                    
                </div>

                <div className="col-sm-12 paginatitton">
                    <Pagination
                        TotalData={data?.totalCount || 0}
                        handlePageChange={handlePageChange}
                        currentPage={page}
                    />
                </div>
            </div>
}

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <DeleteModal handleClose={handleClose} handleDelete={handleDelete} loading={deleteLoading} />
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default Page;
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Form, Input, message, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../Lottie/Loading';
import { editBook, getSingleBook } from '../../Redux/Action/books';
import { AppDispatch, RootState } from '../../Store';
// import Loading from '../../Lottie/Loading';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { getInstructorListing } from '../../Redux/Action/Instructor';
import { clearState } from '../../Redux/Reducer/booksReducer';

const {Option} = Select;

const BookDetailPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const { data: instructorData } = useSelector((state: RootState) => state.instructor);
    const { data: bookData, loading, editLoading, editSuccess, editError } = useSelector((state: RootState) => state.book);
    const { id } = useParams();
    const [form] = Form.useForm();

    const [pageSize, setPageSize] = useState<number>(100);
    const [page, setPage] = useState<number>(1);

    useEffect(() => {
        dispatch(getInstructorListing({ page, pageSize }));
    }, [dispatch])

    useEffect(() => {
        if (id) {
            dispatch(getSingleBook({ id }));
        }
    }, [dispatch, id]);

    const onFinish = (values: any) => {
        const formData = new FormData();
        formData.append('Name', values.Name);
        formData.append('Description', values.Description);
        formData.append('Price', values.Price);
        formData.append('Duration', values.Duration);
        formData.append('AudioLink', values.AudioLink);
        formData.append('PdfLink', values.PdfLink);
        formData.append('Instructor', values.AuthorName);
        formData.append('File', '');

        if(id) {
            dispatch(editBook({id, formData}));
        }
    };   

    useEffect(() => {
        if (bookData) {
            form.setFieldsValue({
                AuthorName:bookData?.InstructorId || '',
                Name: bookData?.Name || '',
                Price: bookData?.Price || '',
                Duration: bookData?.Duration || '',
                PdfLink: bookData?.PdfLink || '',
                AudioLink: bookData?.AudioLink || '',
                Description: bookData?.Description || '',
            });
        }
    }, [bookData, form]);

    useEffect(() => {
        if(editError) {
            message.error(editError);
            dispatch(clearState());
        }
        if(editSuccess) {
            message.success(editSuccess);
            dispatch(clearState());
            navigate('/books');
        }
    }, [editSuccess, editError]);

    return (
        <div className="container-fluid servicess">
            <div className="row">
                <div className="col-sm-12 addnew">
                    <h2>Book Details</h2>
                </div>
                {loading ? (
                    <div><Loading /></div>
                ) : (
                    <div className="col-sm-12" style={{ paddingTop: '30px' }}>
                        <Form layout="vertical" form={form} initialValues={bookData} onFinish={onFinish}>
                            <div className="row">
                                <div className="col-sm-6">
                                    <Form.Item
                                        label="Book Name"
                                        name="Name"
                                    >
                                        <Input placeholder="Name" />
                                    </Form.Item>
                                </div>
                                <div className="col-sm-6">
                                    <Form.Item
                                        label="Author Name"
                                        name="AuthorName"
                                        rules={[{ required: true, message: 'Please select the Instructor!' }]}
                                    >
                                        <Select 
                                            placeholder="Instructor"
                                        >
                                            {instructorData?.items?.map((instructor: any, index: number) => (
                                                <Select.Option value={instructor?._id} key={index}>
                                                    {instructor?.Name}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </div>

                                <div className="col-sm-6">
                                    <Form.Item
                                        label="Book Price"
                                        name="Price"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input the Price!'
                                            },
                                            {
                                                validator: (_, value) => {
                                                    if (!value || (Number(value) > 0)) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(new Error('Price must be greater than 0!'));
                                                }
                                            }
                                        ]}
                                    >
                                        <Input placeholder="Price" />
                                    </Form.Item>
                                </div>

                                <div className="col-sm-6">
                                    <Form.Item
                                        label="Duration"
                                        name="Duration"
                                        rules={[{ required: true, message: 'Please input the Duration in mins or hours!' }]}
                                    >
                                        <Input placeholder="Duration" />
                                    </Form.Item>
                                </div>

                                <div className="col-sm-6">
                                    <Form.Item
                                        label="Audio Link"
                                        name="AudioLink"
                                        rules={[
                                            { 
                                                required: true, 
                                                message: 'Please input the Audio Link!' 
                                            },
                                            {
                                                type: 'url',
                                                message: 'Please enter a valid URL!'
                                            }
                                        ]}
                                    >
                                        <Input placeholder="Audio Link" />
                                    </Form.Item>
                                </div>

                                <div className="col-sm-6">
                                    <Form.Item
                                        label="PdfLink"
                                        name="PdfLink"
                                        rules={[
                                            { 
                                                required: true, 
                                                message: 'Please input the Pdf Link!' 
                                            },
                                            {
                                                type: 'url',
                                                message: 'Please enter a valid URL!'
                                            }
                                        ]}
                                    >
                                        <Input placeholder='PdfLink' />
                                    </Form.Item>
                                </div>

                                <div className="col-sm-12">
                                    <Form.Item
                                        label="Description"
                                        name="Description"
                                        rules={[{ required: true, message: 'Please input the description!' }]}
                                    >
                                        <Input.TextArea rows={5} placeholder="Description" />
                                    </Form.Item>
                                </div>

                                <div className="col-sm-12" style={{textAlign: 'right', marginTop: '20px'}} >
                                    <Button type="primary" htmlType="submit" disabled={editLoading} className='spinnnnerr'>
                                    {editLoading ? 
                                        <>
                                            <Spin indicator={<LoadingOutlined spin />}  /> 
                                        </>
                                        : 
                                        <></> 
                                        }
                                        
                                        Submit</Button>
                                </div>
                            </div>
                        </Form>
                    </div>
                )}
            </div>
        </div>
    );
};

export default BookDetailPage;

import { createSlice } from "@reduxjs/toolkit";

interface courseState {
    loading: boolean;
    editLoading: boolean;
    deleteLoading: boolean;
    error: string | null;
    success: string | null;
    editError: string | null;
    editSuccess: string | null;
    postError: string | null;
    postSuccess: string | null;
    deleteError: string | null;
    deleteSuccess: string | null;
    data: any;
}

const initialState: courseState = {
    loading: false,
    deleteLoading: false,
    editLoading: false,
    error: null,
    success: null,
    editError: null,
    editSuccess: null,
    postError: null,
    postSuccess: null,
    deleteError: null,
    deleteSuccess: null,
    data: {},
};

const courseSlice = createSlice({
    name: "course",
    initialState,
    reducers: {
        courseListingStart(state) {
            state.loading = true;
            state.error = null;
        },

        courseListingSuccess(state, action) {
            state.loading = false;
            state.data = action.payload.data.result;
            state.success = action.payload.data.message;
        },
        courseListingFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        singleCourseStart(state) {
            state.loading = true;
            state.error = null;
        },
        singleCourseSuccess(state, action) {
            state.loading = false;
            state.data = action.payload.data;
            state.success = action.payload.data.message;
        },
        singleCourseFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        editCourseStart(state) {
            state.editLoading = true;
            state.editError = null;
        },
        editCourseSuccess(state, action) {
            state.editLoading = false;
            state.data = action.payload.data;
            state.editSuccess = action.payload.data.message;
        },
        editCourseFailure(state, action) {
            state.editLoading = false;
            state.editError = action.payload;
        },
        addCourseStart(state) {
            state.loading = true;
            state.postError = null;
        },
        addCourseSuccess(state, action) {
            state.loading = false;
            state.data = action.payload.data;
            state.postSuccess = action.payload.data.message;
        },
        addCourseFailure(state, action) {
            state.loading = false;
            state.postError = action.payload;
        },
        deleteCourseStart(state) {
            state.deleteLoading = true;
            state.deleteError = null;
        },
        deleteCourseSuccess(state, action) {
            state.deleteLoading = false;
            state.data = action.payload;
            state.deleteSuccess = action.payload.message;
        },
        deleteCourseFailure(state, action) {
            state.deleteLoading = false;
            state.deleteError = action.payload;
        },
        clearState: (state) => {
            state.error = null;
            state.success = null;
            state.editSuccess = null;
            state.editError = null;
            state.postError = null;
            state.postSuccess = null;
            state.deleteError = null;
            state.deleteSuccess = null;
        },
    },
});

export const {
    courseListingStart,
    courseListingSuccess,
    courseListingFailure,
    singleCourseStart,
    singleCourseFailure,
    singleCourseSuccess,
    editCourseStart,
    editCourseFailure,
    editCourseSuccess,
    addCourseStart,
    addCourseFailure,
    addCourseSuccess,
    deleteCourseStart,
    deleteCourseFailure,
    deleteCourseSuccess,
    clearState,
} = courseSlice.actions;

export default courseSlice.reducer;
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
    serviceListingStart,
    serviceListingSuccess,
    serviceListingFailure,
    deleteserviceFailure,
    deleteserviceStart,
    deleteserviceSuccess,
    getSingleserviceStart,
    getSingleserviceSuccess,
    getSingleserviceFailure,
    editserviceStart,
    editserviceFailure,
    editserviceSuccess,
    addserviceFailure,
    addserviceStart,
    addserviceSuccess,
} from "../Reducer/serviceReducer";
import axios from "axios";
import Cookies from "js-cookie";
import { bookResponse, getSingleBookResponse, PaginationParams, SingleServiceResponse } from "../../interfaces";

export const getServiceListing = createAsyncThunk<bookResponse, PaginationParams>(
    "service",
    async ({ page, pageSize }, thunkAPI) => {
        try {
            thunkAPI.dispatch(serviceListingStart());

            const token = Cookies.get("token");
            const config = {
                headers: {
                    Authorization: token,
                },
                params: {
                    size: pageSize,
                    page,
                },
            };

            const response = await axios.get<bookResponse>(
                `${process.env.REACT_APP_API_URl}ServiceRoutes/ServicesListing`,
                config
            );

            thunkAPI.dispatch(serviceListingSuccess(response));

            return response.data;
        } catch (error: any) {
            const errorMessage = error?.response?.data?.message || "Failed to fetch service";

            thunkAPI.dispatch(serviceListingFailure(errorMessage));
            throw error;
        }
    }
);

export const deleteService = createAsyncThunk(
    "service/deleteService",
    async (id: string, thunkAPI) => {
        try {
            thunkAPI.dispatch(deleteserviceStart());

            const token = Cookies.get("token");
            const config = {
                headers: {
                    Authorization: token,
                },
            };

            const response = await axios.delete(
                `${process.env.REACT_APP_API_URl}ServiceRoutes/SoftDeleteService/${id}`, config
            );

            thunkAPI.dispatch(deleteserviceSuccess(response.data));
            return response.data;
        } catch (error: any) {
            if (error) {
                const errorMessage = error?.response?.data?.message;
                thunkAPI.dispatch(deleteserviceFailure(errorMessage));
                return thunkAPI.rejectWithValue(errorMessage);
            }
        }
    }
);

export const getSingleService = createAsyncThunk<getSingleBookResponse, { id: string }, { rejectValue: string }>(
    "service/getSingleService",
    async ({ id }, thunkAPI) => {
        try {
            thunkAPI.dispatch(getSingleserviceStart());

            const token = Cookies.get("token");
            const config = {
                headers: {
                    Authorization: token,
                },
            };

            const response = await axios.get<getSingleBookResponse>(
                `${process.env.REACT_APP_API_URl}ServiceRoutes/GetSingleService/${id}`,
                config
            );

            thunkAPI.dispatch(getSingleserviceSuccess(response?.data?.data));
            return response.data;
        } catch (error: any) {
            const errorMessage = error?.response?.data?.message || "Failed to fetch service details";
            thunkAPI.dispatch(getSingleserviceFailure(errorMessage));
            return thunkAPI.rejectWithValue(errorMessage);
        }
    }
);

export const addService = createAsyncThunk(
    "service/addService",
    async (formData: FormData, thunkAPI) => {
        try {
            thunkAPI.dispatch(addserviceStart());

            const token = Cookies.get("token");
            const config = {
                headers: {
                    Authorization: token,
                },
            };

            const response = await axios.post(
                `${process.env.REACT_APP_API_URl}ServiceRoutes/CreateService`,
                formData,
                config
            );

            thunkAPI.dispatch(addserviceSuccess(response));
            return response.data;
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || "Failed to add service!";
            thunkAPI.dispatch(addserviceFailure(errorMessage));
            return thunkAPI.rejectWithValue(errorMessage);
        }
    }
);

export const editService = createAsyncThunk<SingleServiceResponse, { id: string, formData: FormData }, { rejectValue: string }>(
    "service/editSerive",
    async ({ id, formData }, thunkAPI) => {
        try {
            thunkAPI.dispatch(editserviceStart());

            const token = Cookies.get("token");
            const config = {
                headers: {
                    Authorization: token,
                },
            };

            const response = await axios.put(
                `${process.env.REACT_APP_API_URl}ServiceRoutes/EditService/${id}`,
                formData,
                config
            );

            thunkAPI.dispatch(editserviceSuccess(response));
            return response.data;
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || "Failed to edit service!";
            thunkAPI.dispatch(editserviceFailure(errorMessage));
            return thunkAPI.rejectWithValue(errorMessage);
        }
    }
);
import React from 'react'
import Sidebar from '../Components/Sidebar/Sidebar'
import ServiceListing from '../Components/Services/ServiceListing'

const Services = () => {
    return (
        <div className='container-fluid'>
            <div className="row">
                <div className="col-sm-3 sidebar">
                    <Sidebar />
                </div>
                <div className="col-sm-9 noSide">
                    <ServiceListing />
                </div>
            </div>
        </div>
    )
}

export default Services

import React from 'react'
import Sidebar from '../Components/Sidebar/Sidebar'
import DetailPage from '../Components/Workshop/DetailPage'

const WorkshopDetails = () => {
    return (
        <div className='container-fluid'>
            <div className="row">
                <div className="col-sm-3 sidebar">
                    <Sidebar />
                </div>
                <div className="col-sm-9 noSide">
                    <DetailPage />
                </div>
            </div>
        </div>
    )
}

export default WorkshopDetails

import React from "react";
import { Pagination as AntPagination } from "antd";

interface PaginationProps {
  TotalData: number;
  handlePageChange: (page: number, pageSize?: number) => void;
  currentPage: number;
}

const Pagination: React.FC<PaginationProps> = ({ TotalData, handlePageChange, currentPage }) => {
  return (
    <nav className="paginationMain pagination">
      <AntPagination
        total={TotalData}
        onChange={handlePageChange}
        current={currentPage}
        showTotal={(total) => `Total ${total} items`}
        showSizeChanger
        // showQuickJumper
      />
    </nav>
  );
};

export default Pagination;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Input, Button, message, Select, InputNumber } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../Store';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { getInstructorListing } from '../../Redux/Action/Instructor';
import { addService } from '../../Redux/Action/service';
import { clearState } from '../../Redux/Reducer/serviceReducer';

// const {option} = Select;

const AddServicePage = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { data } = useSelector((state: RootState) => state.instructor);
    const { postError, postSuccess, loading } = useSelector((state: RootState) => state.service);
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [pageSize, setPageSize] = useState<number>(100);
    const [page, setPage] = useState<number>(1);

    useEffect(() => {
        dispatch(getInstructorListing({ page, pageSize }));
    }, [dispatch])


    useEffect(() => {
        if (postError) {
            message.error(postError);
            dispatch(clearState());
        }
        if (postSuccess) {
            message.success(postSuccess);
            dispatch(clearState());
            navigate('/services')
        }
    }, [postSuccess, postError]);

    const onFinish = (values: any) => {
        dispatch(addService(values));
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <div className='container-fluid servicess'>
            <div className="row">
                <div className="col-sm-12 addnew">
                    <h2>Add New Service</h2>
                </div>

                <div className="col-sm-12" style={{ paddingTop: '30px' }}>
                    <Form
                        form={form}
                        layout='vertical'
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                    >
                        <div className="row">
                            <div className="col-sm-6">
                                <Form.Item
                                    label="Name"
                                    name="Name"
                                    rules={[{ required: true, message: 'Please input the Name!' }]}
                                >
                                    <Input placeholder='Name' />
                                </Form.Item>
                            </div>

                            <div className="col-sm-6">
                                <Form.Item
                                    label="Price"
                                    name="Price"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input the Price!'
                                        }
                                    ]}
                                >
                                    <InputNumber min={1} placeholder='Price' />
                                </Form.Item>
                            </div>


                            <div className="col-sm-6">
                                <Form.Item
                                    label="Duration"
                                    name="Duration"
                                    rules={[{ required: true, message: 'Please input the Duration in mins or hours!' }]}
                                >
                                    <Input placeholder='Duration in mins or hours' />
                                </Form.Item>
                            </div>



                            <div className="col-sm-6">
                                <Form.Item
                                    label="File Link"
                                    name="File"
                                    rules={[
                                        { 
                                            required: true, 
                                            message: 'Please input the File Link!' 
                                        },
                                        {
                                            type: 'url',
                                            message: 'Please enter a valid URL!'
                                        }
                                    ]}
                                >
                                    <Input placeholder='File Link' />
                                </Form.Item>
                            </div>

                            <div className="col-sm-6">
                                <Form.Item
                                    label="Instructor"
                                    name="Instructor"
                                    rules={[{ required: true, message: 'Please select the Instructor!' }]}
                                >
                                    <Select placeholder='Instructor'>
                                        {data?.items?.map((instructor: any, index: number) => (
                                            <Select.Option value={instructor?._id} key={index}>{instructor?.Name}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </div>

                            <div className="col-sm-12">
                                <Form.Item
                                    label="Description"
                                    name="Description"
                                    rules={[{ required: true, message: 'Please input the description!' }]}
                                >
                                    <Input.TextArea placeholder='Book Description' rows={5} />
                                </Form.Item>
                            </div>

                            <div className="col-sm-12" style={{ textAlign: 'right', marginTop: '20px' }}>
                                <Button type="primary" htmlType="submit" disabled={loading} className='spinnnnerr'>
                                    {loading ?
                                        <>
                                            <Spin indicator={<LoadingOutlined spin />} />
                                        </>
                                        :
                                        <></>
                                    }
                                    Submit
                                </Button>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    );
};

export default AddServicePage;
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
    workshopListingStart,
    workshopListingSuccess,
    workshopListingFailure,
    singleWorkshopStart,
    singleWorkshopFailure,
    singleWorkshopSuccess,
    deleteWorkshopStart,
    deleteWorkshopFailure,
    deleteWorkshopSuccess,
    addWorkshopStart,
    addWorkshopSuccess,
    addWorkshopFailure,
    editWorkshopStart,
    editWorkshopSuccess,
    editWorkshopFailure,
} from "../Reducer/workshopReducer";
import axios from "axios";
import Cookies from "js-cookie";
import { quoteListing, singleQuoteRequest, PaginationParams, SingleServiceResponse } from "../../interfaces";

export const getWorkshopsListing = createAsyncThunk<quoteListing, PaginationParams>(
    "workshop",
    async ({ page, pageSize }, thunkAPI) => {
        try {
            thunkAPI.dispatch(workshopListingStart());

            const token = Cookies.get("token");

            const config = {
                headers: {
                    Authorization: token,
                },
                params: {
                    size: pageSize,
                    page,
                },
            };

            const response = await axios.get<quoteListing>(
                `${process.env.REACT_APP_API_URl}WorkShopRoutes/WorkShopsListing`,
                config
            );

            thunkAPI.dispatch(workshopListingSuccess(response));

            return response.data;
        } catch (error: any) {
            const errorMessage = error?.response?.data?.message || "Failed to fetch workshops";

            thunkAPI.dispatch(workshopListingFailure(errorMessage));
            throw error;
        }
    }
);


export const getSingleWorkshop = createAsyncThunk<singleQuoteRequest, { id: string }, { rejectValue: string }>(
    "workshop/getSingleWorkshop",
    async ({ id }, thunkAPI) => {
        try {
            thunkAPI.dispatch(singleWorkshopStart());

            const token = Cookies.get("token");
            const config = {
                headers: {
                    Authorization: token,
                },
            };

            const response = await axios.get<singleQuoteRequest>(
                `${process.env.REACT_APP_API_URl}WorkShopRoutes/GetSingleWorkShop/${id}`,
                config
            );

            thunkAPI.dispatch(singleWorkshopSuccess(response.data));
            return response.data;
        } catch (error: any) {
            const errorMessage = error?.response?.data?.message || "Failed to fetch workshop details";
            thunkAPI.dispatch(singleWorkshopFailure(errorMessage));
            return thunkAPI.rejectWithValue(errorMessage);
        }
    }
);

export const deleteWorkshop = createAsyncThunk(
    "workshop/deleteWorkshop",
    async (id: string, thunkAPI) => {
        try {
            thunkAPI.dispatch(deleteWorkshopStart());

            const token = Cookies.get("token");
            const config = {
                headers: {
                    Authorization: token,
                },
            };

            const response = await axios.delete(
                `${process.env.REACT_APP_API_URl}WorkShopRoutes/SoftDeleteWorkShop/${id}`, config
            );

            thunkAPI.dispatch(deleteWorkshopSuccess(response.data));
            return response.data;
        } catch (error: any) {
            if (error) {
                const errorMessage = error?.response?.data?.message;
                thunkAPI.dispatch(deleteWorkshopFailure(errorMessage));
                return thunkAPI.rejectWithValue(errorMessage);
            }
        }
    }
);

export const addWorkshop = createAsyncThunk(
    "workshop/addWorkshop",
    async (formData: FormData, thunkAPI) => {
        try {
            thunkAPI.dispatch(addWorkshopStart());

            const token = Cookies.get("token");
            const config = {
                headers: {
                    Authorization: token,
                },
            };

            const response = await axios.post(
                `${process.env.REACT_APP_API_URl}WorkShopRoutes/CreateWorkShop`,
                formData,
                config
            );

            thunkAPI.dispatch(addWorkshopSuccess(response));
            return response.data;
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || "Failed to add workshop!";
            thunkAPI.dispatch(addWorkshopFailure(errorMessage));
            return thunkAPI.rejectWithValue(errorMessage);
        }
    }
);

export const editWorkshop = createAsyncThunk<SingleServiceResponse, { id: string, formData: FormData }, { rejectValue: string }>(
    "workshop/editWorkshop",
    async ({ id, formData }, thunkAPI) => {
        try {
            thunkAPI.dispatch(editWorkshopStart());

            const token = Cookies.get("token");
            const config = {
                headers: {
                    Authorization: token,
                },
            };

            const response = await axios.put(
                `${process.env.REACT_APP_API_URl}WorkShopRoutes/EditWorkShop/${id}`,
                formData,
                config
            );

            thunkAPI.dispatch(editWorkshopSuccess(response));
            return response.data;
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || "Failed to edit workshop!";
            thunkAPI.dispatch(editWorkshopFailure(errorMessage));
            return thunkAPI.rejectWithValue(errorMessage);
        }
    }
);
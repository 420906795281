import React from "react";
import "./App.css";
import { useRoutes } from "react-router-dom";
import Login from "./Pages/Login";
import Dashboard from "./Pages/Dashboard";
import Courses from "./Pages/Courses";
import CourseDetail from "./Pages/CourseDetail";
import AddCourse from "./Pages/AddCourse";
import Workshops from "./Pages/Workshops";
import WorkshopDetails from "./Pages/WorkshopDetails";
import Users from "./Pages/Users";
import UsersDetails from "./Pages/UsersDetails";
import Book from "./Pages/Book";
import Cookies from "js-cookie";
import ProtectedRoute from "./Components/ProtectedRoute";
import BookDetail from "./Pages/BookDetail";
import Profile from "./Pages/Profile";
import Instructors from "./Pages/Instructors";
import AddBook from "./Pages/AddBook";
import AddNewWorkshop from "./Pages/AddNewWorkshop";
import InstructorDetails from "./Pages/InstructorDetails";
import CourseSection from "./Pages/CourseSection";
import Services from "./Pages/Services";
import AddService from "./Pages/AddService";
import EditService from "./Pages/EditService";
import AddInstructor from "./Pages/AddInstructor";

function App() {
  const token = Cookies.get("token");
  let element = useRoutes([
    {
      path: "/",
      element: <Login />,
    },
    {
      path: "dashboard",
      element: (
        <ProtectedRoute token={token}>
          <Dashboard />
        </ProtectedRoute>
      ),
    },
    {
      path: "profile",
      element: (
        <ProtectedRoute token={token}>
          <Profile />
        </ProtectedRoute>
      ),
    },
    {
      path: "users",
      element: (
        <ProtectedRoute token={token}>
          <Users />
        </ProtectedRoute>
      ),
    },
    {
      path: "users/:id",
      element: (
        <ProtectedRoute token={token}>
          <UsersDetails />
        </ProtectedRoute>
      ),
    },
    {
      path: "instructors",
      // element: <Instructors />,
      element: (
        <ProtectedRoute token={token}>
          <Instructors />
        </ProtectedRoute>
      ),
    },
    {
      path: "instructors/add",
      element: (
        <ProtectedRoute token={token}>
          <AddInstructor />
        </ProtectedRoute>
      ),
    },
    {
      path: "instructors/:id",
      // element: <InstructorDetails />,
      element: (
        <ProtectedRoute token={token}>
          <InstructorDetails />
        </ProtectedRoute>
      ),
    },
    {
      path: "books",
      // element: <Book />,
      element: (
        <ProtectedRoute token={token}>
          <Book />
        </ProtectedRoute>
      ),
    },
    {
      path: "workshops",
      // element: <Workshops />,
      element: (
        <ProtectedRoute token={token}>
          <Workshops />
        </ProtectedRoute>
      ),
    },
    {
      path: "workshops/add",
      // element: <AddNewWorkshop />,
      element: (
        <ProtectedRoute token={token}>
          <AddNewWorkshop />
        </ProtectedRoute>
      ),
    },
    {
      path: "books/add",
      element: (
        <ProtectedRoute token={token}>
          <AddBook />
        </ProtectedRoute>
      ),
      // element: <AddBook />
    },
    {
      path: "courses",
      element: (
        <ProtectedRoute token={token}>
          <Courses />
        </ProtectedRoute>
      ),
    },    
    {
      path: "courses/:id",
      element: (
        <ProtectedRoute token={token}>
          <CourseDetail />
        </ProtectedRoute>
      ),
      // element: <CourseDetail />,
    },
    {
      path: "courses/CourseSections/:id",
      // element: <CourseSection />,
      element: (
        <ProtectedRoute token={token}>
          <CourseSection />
        </ProtectedRoute>
      ),
    },
    {
      path: "workshops/:id",
      element: (
        <ProtectedRoute token={token}>
          <WorkshopDetails />
        </ProtectedRoute>
      ),
      // element: <WorkshopDetails />,
    },
    {
      path: "courses/add",
      // element: <AddCourse />,
      element: (
        <ProtectedRoute token={token}>
          <AddCourse />
        </ProtectedRoute>
      ),
    },
    {
      path: "books/:id",
      element: (
        <ProtectedRoute token={token}>
          <BookDetail />
        </ProtectedRoute>
      ),
      // element: <BookDetail />,
    },
    {
      path: 'services',
      // element: <Services />
      element: (
        <ProtectedRoute token={token}>
          <Services />
        </ProtectedRoute>
      ),
    },
    {
      path: 'services/add',
      element: (
        <ProtectedRoute token={token}>
          <AddService />
        </ProtectedRoute>
      ),
      // element: <AddService />
    },
    {
      path: 'services/:id',
      element: (
        <ProtectedRoute token={token}>
          <EditService />
        </ProtectedRoute>
      ),
      // element: <EditService />
    }
    
  ]);

  return element;
}

export default App;

import { Form, Input, Button, message } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../Store';
import { clearState } from '../../Redux/Reducer/courseSectionPartsReducer';
import { LoadingOutlined } from '@ant-design/icons';
// import { getInstructorListing } from '../../Redux/Action/Instructor';
import { Spin } from 'antd';
import { addCourseSectionPart } from '../../Redux/Action/courseSectionPart';

interface AddNewCourseSectionPartProps {
    idEditSectionParts?: any;
    handleClose: ()=> void;
    getDataAgain: ()=> void;
}

const AddNewCourseSectionPart: React.FC<AddNewCourseSectionPartProps> = ({ idEditSectionParts, handleClose, getDataAgain }) => {
    const id = idEditSectionParts;
    const dispatch = useDispatch<AppDispatch>();
    const { postSuccess, postError, loading } = useSelector((state: RootState) => state.courseSectionPart);
    
    useEffect(() => {
        if (postError) {
            message.error(postError);
            dispatch(clearState());
        }
        if (postSuccess) {
            message.success(postSuccess);
            dispatch(clearState());
            handleClose();
            getDataAgain();
        }
    }, [postSuccess, postError]);


    const onFinish = (values: any) => {
        const formData = new FormData();
        formData.append('Title', values.Title);
        formData.append('File', values.File);
        formData.append('Duration', values.Duration);
        dispatch(addCourseSectionPart({id, formData}))
    };
    const [form] = Form.useForm();


    useEffect(() => {
        if (idEditSectionParts) {
            form.setFieldsValue({
                Title: idEditSectionParts?.Title,
                File: idEditSectionParts?.File,
                Duration: idEditSectionParts?.Duration
            });
        }
    }, [idEditSectionParts]);

    return (
        <div className="container-fluid servicess">
            <Form onFinish={onFinish} layout="vertical" initialValues={idEditSectionParts}>
                <div className="row">
                    <div className="col-sm-12">
                        <Form.Item
                            label="Title"
                            name="Title"
                            rules={[{ required: true, message: 'Please input the Title!' }]}
                        >
                            <Input placeholder="Title" />
                        </Form.Item>
                    </div>

                    <div className="col-sm-12">
                        <Form.Item
                            label="File"
                            name="File"
                            rules={[{ required: true, message: 'Please input the File URL!' },
                                { type: 'url', message: 'Please enter a valid URL!' }]}
                        >
                            <Input placeholder="File URL" />
                        </Form.Item>
                    </div>

                    <div className="col-sm-12">
                        <Form.Item
                            label="Duration"
                            name="Duration"
                            rules={[{ required: true, message: 'Please input the Duration!' }]}
                        >
                            <Input placeholder="Duration" />
                        </Form.Item>
                    </div>

                    <div className="col-sm-12" style={{ textAlign: 'right' }}>
                        <Button type="primary" htmlType="submit">
                            {loading ? <Spin indicator={<LoadingOutlined spin />} /> : 'Submit'}
                        </Button>
                    </div>
                </div>
            </Form>
        </div>
    );
};

export default AddNewCourseSectionPart;

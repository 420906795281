import { createAsyncThunk } from "@reduxjs/toolkit";
import {
    instructorListingStart,
    instructorListingSuccess,
    instructorListingFailure,
    singleinstructorStart,
    singleinstructorFailure,
    singleinstructorSuccess,
    deleteinstructorStart,
    deleteinstructorFailure,
    deleteinstructorSuccess,
} from "../Reducer/instructorReducer";
import axios from "axios";
import Cookies from "js-cookie";
import { getSingleUserResponse, PaginationParams, userListing } from "../../interfaces";

export const getInstructorListing = createAsyncThunk<userListing, PaginationParams>(
    "instructor",
    async ({ page, pageSize }, thunkAPI) => {
        try {
            thunkAPI.dispatch(instructorListingStart());

            const token = Cookies.get("token");
            const config = {
                headers: {
                    Authorization: token,
                },
                params: {
                    size: pageSize,
                    page,
                },
            };

            const response = await axios.get<userListing>(
                `${process.env.REACT_APP_API_URl}InstructorRoutes/InstructorsListing`,
                config
            );

            thunkAPI.dispatch(instructorListingSuccess(response));

            return response.data;
        } catch (error: any) {
            const errorMessage = error?.response?.data?.message || "Failed to fetch instructor";

            thunkAPI.dispatch(instructorListingFailure(errorMessage));
            throw error;
        }
    }
);


export const getSingleInstructor = createAsyncThunk<getSingleUserResponse, { id: string }, { rejectValue: string }>(
    "instructor/getSingleInstructor",
    async ({ id }, thunkAPI) => {
        try {
            thunkAPI.dispatch(singleinstructorStart());

            const token = Cookies.get("token");
            const config = {
                headers: {
                    Authorization: token,
                },
            };

            const response = await axios.get<getSingleUserResponse>(
                `${process.env.REACT_APP_API_URl}InstructorRoutes/InstructoProfile/${id}`,
                config
            );

            thunkAPI.dispatch(singleinstructorSuccess(response.data));
            return response.data;
        } catch (error: any) {
            const errorMessage = error?.response?.data?.message || "Failed to fetch instructor details";
            thunkAPI.dispatch(singleinstructorFailure(errorMessage));
            return thunkAPI.rejectWithValue(errorMessage);
        }
    }
);

export const deleteInstructor = createAsyncThunk(
    "instructor/deleteInstructor",
    async (id: string, thunkAPI) => {
        try {
            thunkAPI.dispatch(deleteinstructorStart());

            const token = Cookies.get("token");
            const config = {
                headers: {
                    Authorization: token,
                },
            };

            const response = await axios.delete(
                `${process.env.REACT_APP_API_URl}user/${id}`, config
            );

            thunkAPI.dispatch(deleteinstructorSuccess(response.data));
            return response.data;
        } catch (error: any) {
            if (error) {
                const errorMessage = error?.response?.data?.message;
                thunkAPI.dispatch(deleteinstructorFailure(errorMessage));
                return thunkAPI.rejectWithValue(errorMessage);
            }
        }
    }
);

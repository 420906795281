import { combineReducers } from "redux";
import authAdmin from "./Reducer/authAdminReducer";
import course from "./Reducer/courseReducer";
import user from "./Reducer/userReducer";
import workshop from "./Reducer/workshopReducer";
import dashboard from "./Reducer/dashboardReducer";
import book from "./Reducer/booksReducer";
import changeStatus  from "./Reducer/changeStatusReducer";
import instructor from "./Reducer/instructorReducer";
import courseSectionPart from "./Reducer/courseSectionPartsReducer";
import service  from "./Reducer/serviceReducer";

const rootReducer = combineReducers({
    authAdmin: authAdmin,
    course: course,
    user: user,
    workshop: workshop,
    dashboard: dashboard,
    book: book,
    changeStatus: changeStatus,
    instructor: instructor,
    courseSectionPart: courseSectionPart,
    service: service
});

export default rootReducer;
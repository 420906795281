import React from "react";
import { Navigate } from "react-router-dom";

interface ProtectedRouteProps {
  token?: string | null; // token should be passed as a prop
  children: React.ReactElement;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ token, children }) => {
  if (!token || token === "") {
    return <Navigate to="/" replace />;
  }

  // Otherwise, render the protected component
  return children;
};

export default ProtectedRoute;